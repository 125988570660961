<template>
  <div class="container">
    <div id="search">
      <input
        id="search-document"
        type="text"
        placeholder="Search a document..."
        v-model="searchQuery"
        v-on:keypress.enter="searchDocument"
      />
      <button id="search-button" v-on:click="searchDocument" v-if="!searching">
        Search
      </button>
      <button id="cancel-search" v-on:click="cancelSearch" v-if="searching">
        Cancel Search
      </button>
    </div>
    <div class="documents">
      <div v-for="document in documents" :key="document._id">
        <div class="document" v-if="!document.draft">
          <span class="document-name">{{ document.name }}</span>
          <div class="buttons">
            <button class="view-button" v-on:click="viewDoc(document._id)">
              View
            </button>
            <button class="open-button" v-on:click="openDocument(document._id)">
              Open
            </button>
          </div>
        </div>
      </div>
      <document
        v-bind:document_id="this.$store.state.documentDisplayId"
        v-if="this.$store.state.documentDisplay"
      ></document>
    </div>
    <div class="preview" v-if="viewing">
      <div class="preview-header">
        <span>{{ document.name }}</span>
      </div>
      <div class="preview-content" v-html="document.content"></div>
      <div class="preview-footer">
        <button v-on:click="closeView">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";
import Document from "./Document";

export default {
  name: "TicketDocuments",
  components: { Document },
  data() {
    return {
      documents: [],
      searchQuery: "",
      searching: false,
      documentPreview: {},
      viewing: false,
    };
  },
  async created() {
    this.getDocuments();
  },
  methods: {
    getDocuments: async function () {
      try {
        this.documents = await HandleApi.getDocumentList();
      } catch (err) {
        return err;
      }
    },
    viewDoc: async function (id) {
      if (this.viewing) {
        this.closeView();
        this.document = await HandleApi.getDocument(id);
        this.viewing = true;
      } else {
        this.document = await HandleApi.getDocument(id);
        this.viewing = true;
      }
    },
    openDocument: function (id) {
      this.$store.state.documentDisplayId = id;
      this.$store.state.documentDisplay = true;
    },
    closeView: function () {
      this.viewing = false;
    },
    searchDocument: async function () {
      const keyword = this.searchQuery.trim();
      try {
        this.documents = await HandleApi.searchDocument(keyword);
        this.searching = true;
      } catch (err) {
        return err;
      }
    },
    cancelSearch: function () {
      this.searching = false;
      this.searchQuery = "";
      this.getDocuments();
    },
  },
};
</script>

<style scoped>
.documents {
  height: 300px;
  overflow-y: scroll;
  border: 1px solid rgb(240, 240, 240);
  margin-bottom: 5px;
}
.document {
  position: relative;
  padding: 5px;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 10px;
  margin: 5px;
  display: flex;
  align-items: center;
}
.document-name {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
}
.buttons {
  position: absolute;
  right: 5px;
}
.view-button,
.open-button {
  margin-left: 5px;
}
.preview {
  padding: 10px;
  border: 1px solid rgb(240, 240, 240);
  height: 600px;
  display: flex;
  flex-direction: column;
}
.preview-header {
  display: flex;
  justify-content: center;
}
.preview-header > span {
  font-weight: bold;
}
.preview-content {
  height: 100%;
  overflow-y: scroll;
  padding: 10px 5px;
  background: #fafafa;
}
.preview-footer {
  display: flex;
  justify-content: center;
}
#search {
  margin-bottom: 5px;
}
#search-document {
  width: 60%;
  margin-right: 5px;
  font-weight: bold;
}
#search-button {
  background: white;
}
#cancel-search {
  background: #ff3a3a;
}
#search-button,
#cancel-search {
  margin-top: 5px;
  margin-right: 5px;
  width: 150px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 3px 8px;
  border: solid 1px rgb(240, 240, 240);
  border-radius: 40px;
}
.view-button,
.open-button {
  background: #9ad3d1;
  color: white;
  font-weight: bold;
  padding: 2px 8px;
  border: none;
}
button {
  outline: none;
}
</style>
