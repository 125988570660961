<template>
  <div>
    <div class="big-container" v-if="ticketList.length !== 0">
      <div class="ticket-container">
        <div
          class="ticket-display"
          v-for="(ticket, ticketIndex) in ticketList"
          :key="ticket.id"
          @click="
            currentTicket = ticketIndex;
            ticket.seen = true;
          "
          :style="
            ticket._id == ticketList[currentTicket]._id
              ? { borderRight: 'solid 5px red' }
              : { borderRight: 'solid 5px white' }
          "
        >
          <div>
            <span
              class="ticket-name-display"
              v-if="ticket.hasOwnProperty('travel')"
            >
              {{ ticket.travel.name }}
            </span>
            <span class="ticket-name-display" v-else>
              Support
              {{ ticket.clients[0].name + " " + ticket.clients[0].surname }}
            </span>
            <div class="new-messages" v-if="ticket.seen == false"></div>
          </div>
        </div>
      </div>
      <div class="ticket-messages">
        <ticket-message
          :clientNameMap="ticketList[currentTicket].nameMap"
          :matrixConf="ticketList[currentTicket].matrixConf"
          :matrixRoomId="ticketList[currentTicket].matrixRoomId"
          roomType="travel"
          :roomId="ticketList[currentTicket].travel._id"
        ></ticket-message>
      </div>
      <div>
        <ticket-documents-toggler></ticket-documents-toggler>
        <ticket-documents
          v-if="$store.state.ticketToolDocSwitch === 'Docs'"
        ></ticket-documents>
        <div id="toolbox" v-if="$store.state.ticketToolDocSwitch === 'Tools'">
          <div
            class="worldia"
            v-if="
              ticketList[currentTicket].travelData.theme === 'Assistance' ||
              ticketList[currentTicket].travelData.theme === 'Conciergerie'
            "
          >
            <span>Worldia</span
            ><span>{{ ticketList[currentTicket].travelData.theme }}</span>
          </div>
          <img
            v-if="this.ticketList[currentTicket].map"
            class="map"
            :src="this.ticketList[currentTicket].map"
          />
          <div class="customizable-display">
            <div
              class="category-element"
              :key="element.id"
              v-for="(element, elementIndex) in customDisplay[currentTicket]"
            >
              <p
                class="element-title"
                :style="{ backgroundColor: element.color }"
              >
                {{ element.title }}
              </p>
              <div class="button-container">
                <button
                  type="button"
                  @click="customDisplay[currentTicket].splice(elementIndex, 1)"
                >
                  X
                </button>
              </div>
              <hr />
              <div
                class="element-properties"
                :key="property.id"
                v-for="(property, propertyIndex) in element.properties"
              >
                <span>{{ property.title + " : " }}</span>
                <router-link
                  v-if="property.link != '' && property.normal == false"
                  target="_blank"
                  :to="property.link"
                  exact
                  >{{ property.name }}</router-link
                >
                <a
                  v-else-if="property.link != '' && property.normal == true"
                  :href="property.link"
                  target="_blank"
                  >{{ property.name }}</a
                >
                <span v-else>{{ property.name }}</span>
                <div class="button-container">
                  <button
                    type="button"
                    class="remove-element-button"
                    @click="removeProperty(elementIndex, propertyIndex)"
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="toolbar">
            <div class="tool-bar-item" v-for="tool in toolbar" :key="tool.id">
              <div
                class="toolbar-context"
                @click="
                  tool.showContent = !tool.showContent;
                  tool.deploy == '>'
                    ? (tool.deploy = 'v')
                    : (tool.deploy = '>');
                "
              >
                <button class="toolbar-deploy">
                  {{ tool.deploy }}
                </button>
                <span class="toolbar-context-text">{{ tool.context }}</span>
              </div>
              <div class="toolbar-content" v-show="tool.showContent">
                <button
                  class="toolbar-content-item"
                  v-for="item in tool.content"
                  @click="onAction(item.click)"
                  :key="item.key"
                  :style="item.style"
                  v-show="checkShowItem"
                  :disabled="ticketList.length == 0"
                >
                  {{ item.description }}
                </button>
              </div>
            </div>
            <div class="tool-bar-item" v-if="ticketList.length > 0">
              <div
                class="toolbar-context"
                @click="
                  ticketList[currentTicket].note.showContent =
                    !ticketList[currentTicket].note.showContent;
                  ticketList[currentTicket].note.deploy == '>'
                    ? (ticketList[currentTicket].note.deploy = 'v')
                    : (ticketList[currentTicket].note.deploy = '>');
                "
              >
                <button class="toolbar-deploy">
                  {{ ticketList[currentTicket].note.deploy }}
                </button>
                <span class="toolbar-context-text">{{
                  ticketList[currentTicket].note.context
                }}</span>
              </div>
              <div
                class="toolbar-content"
                v-show="ticketList[currentTicket].note.showContent"
              >
                <textarea
                  class="toolbar-notes"
                  v-model="ticketList[currentTicket].note.content"
                  placeholder="You can write notes in this area"
                  @blur="updateNotes()"
                ></textarea>
              </div>
            </div>
            <div class="tool-bar-item">
              <div
                class="toolbar-context"
                @click="
                  ticketList[currentTicket].travel.todoList.showContent =
                    !ticketList[currentTicket].travel.todoList.showContent;
                  ticketList[currentTicket].travel.todoList.deploy == '>'
                    ? (ticketList[currentTicket].travel.todoList.deploy = 'v')
                    : (ticketList[currentTicket].travel.todoList.deploy = '>');
                "
              >
                <button class="toolbar-deploy">
                  {{ ticketList[currentTicket].travel.todoList.deploy }}
                </button>
                <span class="toolbar-context-text">{{
                  ticketList[currentTicket].travel.todoList.context
                }}</span>
              </div>
              <div
                class="toolbar-content"
                v-show="ticketList[currentTicket].travel.todoList.showContent"
              >
                <button
                  class="newTask"
                  @click="
                    ticketList[currentTicket].travel.todoList.content.push({
                      agent: $store.getters.name,
                      startDate: new Date(),
                      closed: false,
                      note: '',
                      show: false,
                      title: 'task title',
                    });
                    updateTodo();
                  "
                >
                  +
                </button>
                <br />

                <br
                  v-show="
                    ticketList[currentTicket].travel.todoList.content.length > 0
                  "
                />
                <div class="open-todo">
                  <div
                    v-for="(task, taskid) in ticketList[currentTicket].travel
                      .todoList.content"
                    :key="task.id"
                    v-show="!task.closed"
                  >
                    <span class="task-tick-border">
                      <span
                        v-html="$twemoji.parse('✔️')"
                        class="open-task-tick"
                        @click="
                          ticketList[currentTicket].travel.todoList.content[
                            taskid
                          ].closed = true;
                          ticketList[currentTicket].travel.todoList.content[
                            taskid
                          ].endDate = new Date();
                          updateTodo();
                        "
                      >
                      </span
                    ></span>
                    <input
                      type="text"
                      v-model="task.title"
                      class="task-title"
                      @blur="updateTodo()"
                    />
                    <button
                      @click="
                        ticketList[currentTicket].travel.todoList.content[
                          taskid
                        ].show =
                          !ticketList[currentTicket].travel.todoList.content[
                            taskid
                          ].show
                      "
                      class="task-show-button"
                    >
                      {{ task.show ? "-" : "+" }}
                    </button>
                    <br />
                    <span class="task-information">{{
                      new Date(task.startDate).toDateString() +
                      " - " +
                      task.agent
                    }}</span>
                    <br />
                    <textarea
                      class="task-notes"
                      v-show="task.show"
                      v-model="task.note"
                      @blur="updateTodo()"
                    >
                    </textarea>
                  </div>
                </div>
                <br
                  v-show="
                    ticketList[currentTicket].travel.todoList.content.length > 0
                  "
                />
                <hr
                  v-show="
                    ticketList[currentTicket].travel.todoList.content.length > 0
                  "
                />
                <br
                  v-show="
                    ticketList[currentTicket].travel.todoList.content.length > 0
                  "
                />
                <div class="closed-todo">
                  <div
                    v-for="(task, taskid) in ticketList[currentTicket].travel
                      .todoList.content"
                    :key="task.id"
                    v-show="task.closed"
                  >
                    <span class="task-tick-border"
                      ><span
                        v-html="$twemoji.parse('✔️')"
                        class="closed-task-tick"
                        @click="
                          ticketList[currentTicket].travel.todoList.content[
                            taskid
                          ].closed = false;
                          delete ticketList[currentTicket].travel.todoList
                            .content[taskid].endDate;
                          updateTodo();
                        "
                      ></span
                    ></span>
                    <input
                      type="text"
                      v-model="task.title"
                      class="task-title"
                      disabled="true"
                    />
                    <button
                      @click="
                        ticketList[currentTicket].travel.todoList.content[
                          taskid
                        ].show =
                          !ticketList[currentTicket].travel.todoList.content[
                            taskid
                          ].show
                      "
                      class="task-show-button"
                    >
                      {{ task.show ? "-" : "+" }}
                    </button>
                    <br />
                    <span class="task-information">{{
                      "closed on " +
                      new Date(task.endDate).toDateString() +
                      " - " +
                      task.agent
                    }}</span>
                    <br />
                    <textarea
                      class="task-notes"
                      v-show="task.show"
                      v-model="task.note"
                      disabled="true"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="email-container"
      v-if="showEmail"
      @click.self="showEmail = false"
    >
      <email
        class="emailForm"
        @emailSent="showEmail = false"
        :currentTicketId="ticketList[currentTicket]._id"
      ></email>
    </div>
    <div
      class="email-container"
      v-if="showDatabaseSearch"
      @click.self="showDatabaseSearch = false"
    >
      <database
        class="database-search-pop"
        :ticketId="ticketList[currentTicket]._id"
        :location="location"
        :activity="activity"
      ></database>
    </div>

    <modal
      name="topology-modal"
      :scrollable="true"
      :adaptive="true"
      width="90%"
      :maxWidth="600"
      height="auto"
      :maxHeight="600"
      classes="modal"
      @closed="cancelClass"
    >
      <div class="modal-header">
        <p v-if="!classSelected">Ticket Category</p>
        <p v-if="classSelected" class="header-selected">
          {{ typology[0][selectedTypo.class] }}
        </p>
        <p v-if="classSelected" class="header-back" v-on:click="cancelClass">
          ◀ Back
        </p>
      </div>
      <div class="modal-body">
        <div v-if="!classSelected">
          <div v-for="(element, index) in typology[0]" :key="element">
            <p class="class" v-on:click="openClass(index)">{{ element }}</p>
          </div>
        </div>
        <div
          v-if="
            (classSelected && !subClassSelected) ||
            (classSelected &&
              subClassSelected &&
              typology[2][selectedTypo.class].length <= 0)
          "
        >
          <div
            v-for="(sub, index) in typology[1][selectedTypo.class]"
            :key="sub"
          >
            <p class="class" v-on:click="selectSub(index)">{{ sub }}</p>
          </div>
          <label class="other-input" for="other-input">Autre : </label>
          <input
            id="other-input"
            class="other-input"
            type="text"
            placeholder="Other..."
            v-model="selectedTypo.custom"
          /><button v-on:click="validateCustomSub">Valider</button>
        </div>
        <div
          v-if="
            classSelected &&
            subClassSelected &&
            typology[2][selectedTypo.class].length > 0
          "
        >
          <div
            v-for="(final, index) in typology[2][selectedTypo.class]"
            :key="final"
          >
            <p class="class" v-on:click="selectFinal(index)">{{ final }}</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <p>
          Selectioné :
          <span class="selected-typo" v-if="selectedTypo.class !== ''">{{
            typology[0][selectedTypo.class]
          }}</span
          ><span
            v-if="
              (selectedTypo.subclass !== '' && selectedTypo.custom == '') ||
              selectedTypo.custom !== ''
            "
          >
            ➤
          </span>
          <span
            class="selected-typo selected-sub"
            v-if="selectedTypo.subclass !== '' && selectedTypo.custom == ''"
          >
            {{ typology[1][selectedTypo.class][selectedTypo.subclass] }}</span
          >
          <span
            class="selected-typo selected-sub"
            v-if="selectedTypo.custom !== ''"
          >
            {{ selectedTypo.custom }}</span
          >
        </p>
        <p class="selected-typo" v-if="selectedTypo.finalclass !== ''">
          {{ typology[2][selectedTypo.class][selectedTypo.finalclass] }}
        </p>
        <button
          class="close-ticket"
          v-if="
            selectedTypo.class !== '' &&
            (selectedTypo.subclass !== '' || selectedTypo.custom !== '') &&
            (selectedTypo.finalclass !== '' ||
              typology[2][selectedTypo.class].length <= 0)
          "
          v-on:click="closeTicket"
        >
          Close Ticket
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import TicketMessage from "./TicketMessages.vue";
import HandleApi, { azureSasUrl } from "../apiHandle";
import Email from "./Email.vue";
import Database from "./Database.vue";
import TicketDocumentsToggler from "./TicketDocumentsToggler";
import TicketDocuments from "./TicketDocuments";
import { BlobServiceClient, BlockBlobClient } from "@azure/storage-blob";

export default {
  name: "Ticket",
  components: {
    TicketDocuments,
    TicketDocumentsToggler,
    TicketMessage,
    Email,
    Database,
  },
  data() {
    return {
      newticketTimeout: null,
      sendlistTimeout: null,
      ticketList: [],
      currentTicket: -1,
      showEmail: false,
      location: false,
      activity: false,
      showDatabaseSearch: false,
      customDisplay: [],
      classSelected: false,
      subClassSelected: false,
      selectedTypo: {
        class: "",
        subclass: "",
        custom: "",
        finalclass: "",
      },
      typology: [
        [
          "Lancement service",
          "Demande renseignements",
          "Demande de service",
          "Demande de réservation",
          "Demande de confirmation",
          "Interrogation services TA",
          "Conversation",
          "Situation d'urgence",
          "Relance",
          "Réclamation/retour Agences",
        ],
        [
          ["Prise de contact et ouverture du service"],
          [
            "Transport",
            "Loisir",
            "Restauration",
            "Activités",
            "Pratique",
            "Santé",
            "Culture",
            "Hebergement",
            "Boutique",
            "COVID",
            "Demandes insolites (tatouage etc...)",
            "Climat",
            "Us et coutumes",
            "Demandes illicites (prostitution, drogue etc...)",
          ],
          ["Idées pour demande en mariage ", "Pour cadeaux surprise"],
          [
            "Restaurant",
            "Taxi voiture",
            "Taxi moto",
            "Uber",
            "Limousine ou voiture de luxe",
            "Navette",
            "Avion",
            "Train",
            "Hélocoptère",
            "Vélo",
            "Pass-Transport",
            "Spectacle (concert, cinéma, évènement sportif...)",
            "Bateau",
            "Montgolfière",
            "Demandes insolites",
            "Scooter",
            "Guide local",
            "Activités",
            "Hebergement (hotel, maison d'hôte, auberge...)",
            "Loisirs",
          ],
          [
            "Restaurant",
            "Taxi voiture",
            "Taxi moto",
            "Uber",
            "Limousine ou voiture de luxe",
            "Navette",
            "Avion",
            "Train",
            "Hélocoptère",
            "Vélo",
            "Pass-Transport",
            "Spectacle (concert, cinéma, évènement sportif...)",
            "Bateau",
            "Montgolfière",
            "Demandes insolites",
            "Scooter",
            "Guide local",
            "Activités",
            "Hebergement (hotel, maison d'hôte, auberge...)",
            "Loisirs",
          ],
          [
            "Qu'inclus le service",
            "Si on peut faire les réservations pour eux",
            "Si nous réservons comment payer et est ce qu'il y a un coût supl.",
            "Si c'est un robot ou des humain derrière",
          ],
          [
            "Partage de photos",
            "Partage d'expérience ",
            "Proposition du client d'un resto ou activtié découverte par hasard",
          ],
          [
            "Perte de papiers d'identité ou passeport",
            "Perte de clés",
            "Perte de moyens de paiement",
            "Attentat",
            "Problème climatique",
            "Panne de voiture",
            "Perdu",
            "Oubli d'un objet ou autre (dans l'hôtel, avion...)",
            "Retard pour un resto ou un hôtel",
            "Raté l'avion",
            "Crise sanitaire",
            "Est ce que c'est bien inclus dans leur package",
            "Problème de chambre ou d'hôtel",
            "Agression",
            "Problème de santé",
            "Décès",
            "Rapatriement",
          ],
          ["Prise de nouvelles", "Choix non determiné", "Suggestion, conseil"],
          [
            "Problème sur une réservation",
            "Retour sur une insatisfaction client ",
            "Accessibilité à la conciergerie",
          ],
        ],
        [
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [],
          [],
          [
            "Prise en compte en moins de 5 minutes",
            "Prise en compte en moins de 10 minutes",
            "Prise en compte en moins de 15 minutes",
            "Prise en compte en mois de 20 minutes",
            "Prise en compte supérieure à 20 minutes",
          ],
          [
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
          [
            "Demande clôturée et satisfaction OK en moins de 20 min",
            "Demande clôturée et satisfaction OK en plus de 20 min",
            "Demande clôturée mais satisfaction no OK en moins de 20 min",
            "Demande clôturée mais satisfaction no OK en plus de 20 min",
          ],
        ],
      ],
      toolbar: [
        {
          showContent: true,
          context: "Information",
          deploy: ">",
          content: [
            {
              description: "Client Profile",
              style: { backgroundColor: "#009fbc" },
              click: "clientProfile",
              show: true,
            },
            {
              description: "Travel Information",
              style: { backgroundColor: "#aa1853" },
              click: "travelInformation",
              show: true,
            },
            {
              description: "Agency Information",
              style: { backgroundColor: "#008467" },
              click: "agencyInformation",
              show: true,
            },
          ],
        },
        {
          showContent: true,
          context: "Ticket Actions",
          deploy: ">",
          content: [
            {
              description: "Next Ticket",
              style: { backgroundColor: "#008467" },
              click: "newTicket",
              show: true,
            },
            {
              description: "Transfer Ticket",
              style: { backgroundColor: "#009fbc" },
              click: "interruptTicket",
              show: true,
            },
            {
              description: "Close Ticket",
              style: { backgroundColor: "#aa1853" },
              click: "closeTicket",
              show: true,
            },
          ],
        },
        /*{
          showContent: false,
          context: "Search Database",
          deploy: ">",
          content: [
            {
              description: "Generic Search",
              style: { backgroundColor: "#034c57" },
              click: "genericSearch",
              show: true,
            },
            {
              description: "City Search",
              style: { backgroundColor: "#00909f" },
              click: "citySearch",
              show: true,
            },
            {
              description: "Activity Search",
              style: { backgroundColor: "#9ad3d1" },
              click: "activitySearch",
              show: true,
            },
          ],
        },
        {
          showContent: false,
          context: "Communication",
          deploy: ">",
          content: [
            {
              description: "Send Email",
              style: { backgroundColor: "#009fbc" },
              click: "sendEmail",
              show: true,
            },
          ],
        },*/
      ],
      containerClient: null,
    };
  },
  methods: {
    openClass: function (id) {
      this.classSelected = true;
      this.selectedTypo.class = id;
    },
    cancelClass: function () {
      this.classSelected = false;
      this.subClassSelected = false;
      this.selectedTypo.class = "";
      this.selectedTypo.subclass = "";
      this.selectedTypo.custom = "";
      this.selectedTypo.finalclass = "";
    },
    selectSub: function (value) {
      this.subClassSelected = true;
      this.selectedTypo.custom = "";
      this.selectedTypo.subclass = value;
    },
    validateCustomSub: function () {
      this.subClassSelected = true;
    },
    selectFinal: function (value) {
      this.selectedTypo.finalclass = value;
    },
    onAction: function (event) {
      switch (event) {
        // Information
        case "clientProfile":
          this.clientProfile();
          break;
        case "travelInformation":
          this.travelInformation();
          break;
        case "agencyInformation":
          this.agencyInformation();
          break;
        //Ticket Actions
        case "newTicket":
          this.newTicket();
          break;
        case "interruptTicket":
          this.interruptTicket();
          break;
        case "closeTicket":
          this.closeTicketModal();
          break;
        //Search Database
        case "genericSearch":
          this.genericSearch();
          break;
        case "citySearch":
          this.citySearch();
          break;
        case "activitySearch":
          this.activitySearch();
          break;
        //Communication
        case "sendEmail":
          this.sendEmail();
          break;
      }
    },
    newTicket: async function () {
      try {
        if (this.newticketTimeout) clearTimeout(this.newticketTimeout);
        this.$store.dispatch("load", "Fetching Ticket");
        if (this.currentTicket >= 0) {
          await HandleApi.changeTicketStatus(
            this.ticketList[this.currentTicket]._id,
            {
              newStatus: "pending",
              agentId: this.$store.getters.userId,
            }
          );
        }

        let res = await HandleApi.requestNextTicket({
          agentId: this.$store.getters.userId,
        });
        res["seen"] = true;
        let nameMap = {};

        for (let i = 0; i < this.ticketList.length; i++) {
          this.ticketList[i].ignoreUpdate = true;
        }
        res["nameMap"] = nameMap;
        res["ignoreUpdate"] = false;
        if (res.hasOwnProperty("notes")) {
          res["note"] = {
            showContent: false,
            context: "Notes",
            deploy: ">",
            content: res.notes,
          };
        } else {
          res["note"] = {
            showContent: false,
            context: "Notes",
            deploy: ">",
            content: "",
          };
        }
        res.travelData = await HandleApi.getTravelInformationMinified(
          res.travel._id
        );
        res.travelData = res.travelData.travel;
        if (res.travel) {
          if (res.travel.todoList) {
            for (let i = 0; i < res.travel.todoList.length; i++) {
              res.travel.todoList[i].show = false;
            }
            res.travel["todoList"] = {
              showContent: false,
              context: "to-do",
              deploy: ">",
              content: res.travel.todoList,
            };
          } else {
            res.travel["todoList"] = {
              showContent: false,
              context: "to-do",
              deploy: ">",
              content: [],
            };
          }
          let temp = await HandleApi.getTravelInformationMinified(
            res.travel._id
          );
          if (temp.travel.imageMapStopover) {
            res["map"] = temp.travel.imageMapStopover;
          }
        }
        this.ticketList.unshift(res);
        this.customDisplay.unshift([]);
        this.currentTicket = 0;

        let source = require("../assets/notification.wav");
        let sound = new Audio(source);
        sound.play();

        this.$store.dispatch(
          "push_notification_message",
          "new ticket requested with success"
        );
        this.$store.dispatch("unload");
        clearTimeout(this.newticketTimeout);
      } catch (err) {
        this.$store.dispatch("unload", "Requesting Data");
        if (err.response) {
          if (err.response.data == "no unhandled ticket") {
            this.$store.dispatch(
              "push_error_message",
              err.response.data + " next one will be automatically requested"
            );
            this.newticketTimeout = setTimeout(this.newTicket, 30000);
          } else {
            this.$store.dispatch("push_error_message", err.response.data);
          }
        } else {
          this.$store.dispatch("push_error_message", err);
        }
      }
    },
    interruptTicket: async function () {
      await HandleApi.transferTicketList({
        ticketList: [this.ticketList[this.currentTicket]._id],
        agentId: this.$store.getters.userId,
      })
        .then(() => {
          this.ticketList.splice(this.currentTicket, 1);
          this.customDisplay.splice(this.currentTicket, 1);
          this.$store.dispatch(
            "push_notification_message",
            "ticket interrupted with success"
          );
          if (!this.ticketList.length) {
            this.currentTicket = -1;
            this.newTicket();
          }
        })
        .catch((err) => this.$store.dispatch("push_error_message", err));
    },
    closeTicket: async function () {
      let typology = {
        class: this.selectedTypo.class,
        subClass: this.selectedTypo.subclass,
        final: this.selectedTypo.finalclass,
      };
      if (this.selectedTypo.custom !== "") {
        typology.subClass = this.selectedTypo.custom;
      }
      if (this.selectedTypo.finalclass === "") {
        typology.final = "none";
      }
      console.log("close ticket");
      await HandleApi.changeTicketStatus(
        this.ticketList[this.currentTicket]._id,
        {
          newStatus: "closed",
          agentId: this.$store.getters.userId,
          typology: typology,
        }
      )
        .then(() => {
          this.ticketList.splice(this.currentTicket, 1);
          this.customDisplay.splice(this.currentTicket, 1);
          this.$store.dispatch(
            "push_notification_message",
            "ticket closed with success"
          );
          this.$modal.hide("topology-modal");
          if (!this.ticketList.length) {
            this.currentTicket = -1;
            this.newTicket();
          }
        })
        .catch((err) => this.$store.dispatch("push_error_message", err));
    },
    closeTicketModal: function () {
      this.$modal.show("topology-modal");
    },
    sendEmail: function () {
      this.showEmail = true;
    },
    clientProfile: async function () {
      try {
        var self = this;
        this.ticketList[this.currentTicket].clients.forEach(async function (
          element
        ) {
          let temp = await HandleApi.getClientInformationMinified(element._id);
          console.log(temp);
          self.customDisplay[self.currentTicket].push({
            title: temp.name + " " + temp.surname,
            color: "#009fbc",
            properties: [
              {
                title: "profile",
                name: "link",
                link: "/client/" + temp._id,
                normal: false,
              },
              {
                title: "gender",
                name: temp.gender,
                link: "",
                normal: true,
              },
              {
                title: "language",
                name: temp.language,
                link: "",
                normal: true,
              },
            ],
          });
        });
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data);
      }
    },
    agencyInformation: async function () {
      try {
        let temp = await HandleApi.getAgencyInformationMinified(
          this.ticketList[this.currentTicket].tourAgency._id
        );
        let properties = [
          {
            title: "page",
            name: "link",
            link: "/agency/" + temp.agency._id,
            normal: false,
          },
        ];
        if (temp.agency.hasOwnProperty("phone")) {
          properties.push({
            title: "phone",
            name: temp.agency.phone,
            link: "",
            normal: true,
          });
        }
        if (temp.agency.hasOwnProperty("emergencyPhone")) {
          properties.push({
            title: "emergency phone",
            name: temp.agency.emergencyPhone,
            link: "",
            normal: true,
          });
        }
        if (temp.agency.hasOwnProperty("website")) {
          properties.push({
            title: "website",
            name: temp.agency.website,
            link: temp.agency.website,
            normal: true,
          });
        }
        if (temp.agency.hasOwnProperty("email")) {
          properties.push({
            title: "email",
            name: temp.agency.email,
            link: "",
            normal: true,
          });
        }
        this.customDisplay[this.currentTicket].push({
          title: temp.agency.name,
          properties: properties,
          color: "#008467",
        });
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data);
      }
    },
    travelInformation: async function () {
      try {
        let temp = await HandleApi.getTravelInformationMinified(
          this.ticketList[this.currentTicket].travel._id
        );
        console.log(temp);
        let properties = [
          {
            title: "page",
            name: "link",
            link: "/travel/" + temp.travel._id,
            normal: false,
          },
          {
            title: "start",
            name: new Date(temp.travel.startDate).toDateString(),
            link: "",
            normal: true,
          },
          {
            title: "end",
            name: new Date(temp.travel.endDate).toDateString(),
            link: "",
            normal: true,
          },
          {
            title: "place",
            name: temp.travel.place,
            link: "",
            normal: true,
          },
          {
            title: "ghost option",
            name: temp.travel.watchConversation,
            link: "",
            normal: true,
          },
          /*{
            title: "own app option",
            name: temp.travel.ownApp,
            link: "",
            normal: true,
          },*/
        ];
        if (temp.travel.hasOwnProperty("roadmap")) {
          properties.push({
            title: "roadmap",
            name: "link",
            link: temp.travel.roadmap,
            normal: true,
          });
        }
        if (temp.travel.files.length) {
          for (const fileUrl of temp.travel.files) {
            properties.push({
              title: "file",
              name: fileUrl
                .split("/")
                .at(-1)
                .split("?")[0]
                .replaceAll("%20", " "),
              link: fileUrl,
              normal: true,
            });
          }
        }
        this.containerClient = new BlobServiceClient(
          azureSasUrl
        ).getContainerClient("travel" + temp.travel._id);
        if (await this.containerClient.exists()) {
          const roadmapBlobClient =
            this.containerClient.getBlockBlobClient("roadmap.pdf");
          if (await roadmapBlobClient.exists()) {
            properties.push({
              title: "roadmap",
              name: "link",
              link:
                azureSasUrl.split("?")[0] +
                "travel" +
                temp.travel._id +
                "/roadmap.pdf?" +
                azureSasUrl.split("?")[1],
              normal: true,
            });
          }
        }

        this.customDisplay[this.currentTicket].push({
          title: temp.travel.name,
          properties: properties,
          color: "#aa1853",
        });
      } catch (err) {
        if (err.response) {
          this.$store.dispatch("push_error_message", err.response.data);
        } else {
          console.log(err);
        }
      }
    },
    updateNotes: async function () {
      await HandleApi.updateTicketNotes(
        this.ticketList[this.currentTicket]._id,
        {
          notes: this.ticketList[this.currentTicket].note.content,
          agentId: this.$store.getters.userId,
        }
      ).catch((err) =>
        this.$store.dispatch("push_error_message", err.response.data)
      );
    },
    genericSearch() {
      this.location = false;
      this.activity = false;
      this.showDatabaseSearch = true;
    },
    citySearch() {
      this.location = true;
      this.activity = false;
      this.showDatabaseSearch = true;
    },
    activitySearch() {
      this.location = false;
      this.activity = true;
      this.showDatabaseSearch = true;
    },
    transferAllTickets: function () {
      let transferTicketList = [];
      this.ticketList.forEach((element) =>
        transferTicketList.push(element._id)
      );
      HandleApi.transferTicketList({
        ticketList: transferTicketList,
        agentId: this.$store.getters.userId,
      });
    },
    removeProperty(elementIndex, propertyIndex) {
      this.customDisplay[this.currentTicket][elementIndex].properties.splice(
        propertyIndex,
        1
      );
    },
    async sendListOfHandledTickets() {
      try {
        let ticketList = this.ticketList.map((ticket) => ticket._id);
        await HandleApi.confirmTicketHandling(this.$store.getters.userId, {
          ticketList: ticketList,
        });
      } catch (err) {
        console.log(err);
        this.$store.dispatch("push_error_message", err.response.data);
      }
      this.sendlistTimeout = setTimeout(this.sendListOfHandledTickets, 10000);
    },
    async updateTodo() {
      try {
        await HandleApi.updateTodo(
          this.ticketList[this.currentTicket].travel._id,
          {
            todoList:
              this.ticketList[this.currentTicket].travel.todoList.content,
          }
        );
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data || err);
      }
    },
  },
  computed: {
    checkShowItem: function (item) {
      if (item.description == "Travel Information") {
        if (this.showTravelButton) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    showTravelButton: function () {
      return this.ticketList[this.currentTicket].travel ? true : false;
    },
  },
  async created() {
    await this.newTicket();
    await this.sendListOfHandledTickets();
  },
  beforeDestroy(to, from, next) {
    clearTimeout(this.newticketTimeout);
    clearTimeout(this.sendlistTimeout);
    this.transferAllTickets();
    next();
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.newticketTimeout);
    clearTimeout(this.sendlistTimeout);
    this.transferAllTickets();
    next();
  },
};
</script>

<style scoped>
.worldia {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.worldia span:nth-child(1) {
  margin-right: 10px;
  font-weight: bold;
  font-size: 1.5rem;
}
.worldia span:nth-child(2) {
  margin-right: 10px;
  font-weight: normal;
  font-size: 1.5rem;
  text-decoration: underline;
}

.big-container {
  display: grid;
  grid-template-columns: 300px 1fr 500px;
}

.ticket-display {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  height: 60px;
  justify-content: middle;
  margin-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
}

.ticket-display:hover {
  background: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1) 0%,
    rgba(253, 253, 253, 1) 100%
  );
  cursor: pointer;
}

.new-messages {
  background-color: red;
  border-radius: 10px;
  height: 10px;
  width: 10px;
  float: right;
  position: relative;
  top: 45px;
  margin-right: 10px;
}

.toolbar {
  margin-left: 10px;
}

.toolbar-context {
  height: 60px;
  line-height: 60px;
  background: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1) 0%,
    rgba(245, 245, 245, 1) 100%
  );
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.toolbar-deploy {
  margin-right: 10px;
  font-size: 20px;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  width: 30px;
}

.toolbar-context:hover {
  cursor: pointer;
}

.toolbar-context-text {
  font-size: 20px;
}

.toolbar-content {
  padding: 10px;
  border: 1px solid lightgrey;
  border-top: none;
  border-radius: 5px;
}

.toolbar-content-item {
  height: 30px;
  border-radius: 5px;
  color: white;
  border: none;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.toolbar-notes {
  border: 1px solid lightgrey;
  width: 100%;
  overflow: auto;
  outline: none;
  resize: vertical;
  max-height: 300px;
}

.errorDisplay {
  position: absolute;
  bottom: 10px;
  right: 20px;
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  margin: 0 auto;
  border-radius: 10px;
  height: 27px;
  padding: 10px;
  padding-top: 15px;
  font-size: 17px;
}
.closeError {
  margin-left: 20px;
  border: 2px solid #ecb2b2;
  padding: 3px;
}

.closeError:hover {
  border: 2px solid red;
  cursor: pointer;
}

.email-container {
  position: absolute;
  height: calc(100% + 80px);
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  top: 0px;
  left: 0;
}

.emailForm {
  width: 70%;
  height: 70%;
  margin-top: 10%;
  margin-left: 15%;
  z-index: 101;
  background-color: white;
}

.database-search-pop {
  width: 70%;
  height: 70%;
  margin: auto;
  margin-top: 10%;
  margin-left: 15%;
  z-index: 101;
  background-color: white;
  padding: 10px;
  overflow-y: scroll;
}

.customizable-display {
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid darkgrey;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;
  padding: 10px;
  resize: vertical;
}

.customizable-display .category-element {
  display: inline-block;
  text-align: center;
  margin: 10px;
}

.customizable-display .element-title {
  padding: 10px;
  border-radius: 10px;
  color: white;
  display: inline-block;
}

.customizable-display .button-container {
  display: inline-block;
  margin-left: 10px;
}

.customizable-display button {
  color: white;
  background-color: lightgrey;
  font-size: 10px;
  padding: 5px;
  border-radius: 10px;
  border: none;
  height: 20px;
  width: 20px;
}

.customizable-display button:hover {
  background-color: darkgrey;
  cursor: pointer;
}

.newTask {
  float: right;
  color: white;
  border: none;
  background-color: #00909f;
  font-size: 20px;
  font-weight: bold;
}

.task-tick-border {
  border: solid 2px lightgrey;
}

.open-task-tick {
  opacity: 0;
}

.open-task-tick:hover {
  opacity: 1;
  cursor: pointer;
}

.closed-task-tick {
  opacity: 1;
}

.closed-task-tick:hover {
  opacity: 0;
  cursor: pointer;
}

.task-show-button {
  background-color: white;
  border: 2px solid white;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  padding: 0;
  margin-left: 5px;
  font-size: 15px;
  font-weight: bold;
}

.task-show-button:hover {
  cursor: pointer;
  border-color: black;
}

.task-title {
  border: 2px solid white;
  padding: 5px;
  border-radius: 5px;
}

.task-title:hover {
  border-color: lightgrey;
}

.closed-todo .task-title {
  text-decoration: line-through;
}

.task-notes {
  height: 100px;
  width: 90%;
}

.task-information {
  font-size: 12px;
  color: rgb(100, 100, 100);
}
.modal {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.modal p {
  margin: 5px 10px 0 10px;
}
.modal-header * {
  margin: 0;
}
.modal-header {
  background: #e8e8e8;
  width: 100%;
  position: relative;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.modal-header p {
  font-size: 115%;
}
.modal-body {
  overflow-y: scroll;
}
.modal-footer {
  background: #e8e8e8;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  box-shadow: -2px -2px -2px -1px rgba(0, 0, 0, 0.2);
}
.header-back {
  display: block;
  position: absolute;
  right: 20px;
  top: 0;
  font-weight: bold;
  font-size: 115%;
  cursor: pointer;
}
.other-input {
  margin: 5px 0 5px 10px;
}
.close-ticket {
  justify-self: center;
  align-self: center;
  margin: 5px 0;
  height: 30px;
  border-radius: 5px;
  color: white;
  border: none;
  font-weight: bold;
  background-color: rgb(170, 24, 83);
  cursor: pointer;
}
.class {
  margin: 5px 15px;
  cursor: pointer;
}
.class::before,
.other-input::before {
  content: "> ";
  font-weight: bold;
}
.selected-typo {
  font-weight: bold;
  font-size: 115%;
}
.selected-sub {
  text-decoration: underline;
}
.map {
  display: block;
  margin: 5px auto;
  height: 250px;
}
</style>
