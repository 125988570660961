<template>
  <div class="container">
    <form class="toggle">
      <input
        type="radio"
        id="tools"
        name="choice"
        value="tools"
        checked
        v-on:change="changeSwitch('tools')"
      />
      <label for="tools">Tools</label>

      <input
        type="radio"
        id="docs"
        name="choice"
        value="docs"
        v-on:change="changeSwitch('docs')"
      />
      <label for="docs">Documents</label>

      <div id="flap"><span class="content" v-text="flapContent"></span></div>
    </form>
  </div>
</template>

<script>
export default {
  name: "TicketDocumentsToggler",
  data() {
    return {
      flapContent: "Tools",
    };
  },
  methods: {
    changeSwitch: function (s) {
      if (s == "tools") {
        setTimeout(() => {
          this.flapContent = "Tools";
          this.$store.state.ticketToolDocSwitch = "Tools";
        }, 200);
      } else if (s == "docs") {
        setTimeout(() => {
          this.flapContent = "Documents";
          this.$store.state.ticketToolDocSwitch = "Docs";
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
.container {
  perspective: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.toggle {
  position: relative;
  border: solid 3px #9ad3d1;
  border-radius: 55px;
  transition: transform cubic-bezier(0, 0, 0.3, 2) 0.4s;
  transform-style: preserve-3d;
  perspective: 800px;
}
.toggle > input[type="radio"] {
  display: none;
}
.toggle > #tools:checked ~ #flap {
  transform: rotateY(-180deg);
}
.toggle > #tools:checked ~ #flap > .content {
  transform: rotateY(-180deg);
}
.toggle > #docs:checked ~ #flap {
  transform: rotateY(0deg);
}
.toggle > label {
  display: inline-block;
  min-width: 170px;
  padding: 10px;
  font-size: 25px;
  text-align: center;
  color: #9ad3d1;
}
.toggle > #flap,
.toggle > label {
  font-weight: bold;
  text-transform: capitalize;
}
.toggle > #flap {
  position: absolute;
  top: calc(0px - 3px);
  left: 50%;
  height: calc(100% + 3px * 2);
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  background-color: #9ad3d1;
  border-top-right-radius: 55px;
  border-bottom-right-radius: 55px;
  transform-style: preserve-3d;
  transform-origin: left;
  transition: transform cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
}
.toggle > #flap > .content {
  color: white;
  transition: transform 0s linear 0.25s;
  transform-style: preserve-3d;
}
</style>
